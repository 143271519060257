<template>
  <base-view icon="user" :title="getTitle">
    <!-- Content-->

    <!-- Account page navigation-->
    <nav class="my-nav nav nav-borders">
      <router-link
        :to="{ name: 'Profile' }"
        exact-active-class="active"
        class="nav-link my-active ml-0"
      >
        Perfil
      </router-link>
      <!-- <router-link :to="{ name: 'Notificaciones' }" exact-active-class="active" class="nav-link">
          Notificaciones
        </router-link> -->
    </nav>

    <hr class="mt-0 mb-4" />

    <!-- Main view -->
    <router-view :title="title"></router-view>
  </base-view>
</template>

<script>
export default {
  name: 'ProfileView',

  props: {
    title: {
      type: String,
      required: true,
      default: ''
    }
  },

  data () {
    return {
      localTitle: this.title
    }
  },

  watch: {
    '$route.meta.title' (newTitle) {
      this.localTitle = newTitle
    }
  },

  computed: {
    getTitle () {
      return `Configuración - ${this.localTitle}`
    }
  }
}
</script>

<style lang="scss" scoped>
.my-nav {
  @media screen and (max-width: 500px) {
    display: flex !important;
    flex-direction: column !important;
  }
}

.my-active {
  @media screen and (max-width: 500px) {
    margin: 0 1rem !important;
  }
}
</style>
